
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import { MenuComponent } from "@/assets/ts/components";

import EntityTable from "@/components/EntityTable.vue";
import moment from "moment/moment";

export default defineComponent({
  name: "kt-widget-12",
  components: {
    EntityTable,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      filters: {
        id: "",
        type: "",
        dateBetween: [
          moment().subtract(7, "days").format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        event: "",
        author: "",
        string: "",
      },
      columns: [
        {
          name: "id",
          label: "Id",
        },
        {
          name: "created_at",
          label: "Fecha",
        },
        {
          name: "author",
          label: "Autor",
        },
        {
          name: "ip_address",
          label: "IP",
        },
        {
          name: "action",
          label: "Tipo de Cambio",
        },
        {
          name: "entity",
          label: "Entidad",
        },
        {
          name: "details",
          label: "Detalles",
        },
        {
          name: "signData",
          label: "Firma",
        },
      ],
      authorList: [],
      auditModelTypes: {
        donor: "Donante",
        user: "Usuario",
        role: "Rol",
        transport: "Transportista",
      },
      auditModels: [],
      eventList: {
        updated: "Modificación",
        created: "Creación",
        attached: "Vinculación",
        detached: "Desvinculación",
      },
    };
  },
  mounted() {
    this.getAuthorsList();
  },
  methods: {
    autoComplete(value) {
      this.filters.string = value;
      this.getEntityList();
    },
    getAuthorsList() {
      ApiService.query("index/users", {
        params: {},
      }).then((response) => {
        this.authorList = response.data;
      });
    },
    getEntityList() {
      (this.$refs.auditTable as any).retrieveData();
      if (this.filters.type !== "") {
        ApiService.query("audit/entities", {
          params: {
            filterType: this.filters.type,
            filterString: this.filters.string,
          },
        }).then((response) => {
          this.auditModels = response.data.data;
        });
      } else {
        this.filters.id = "";
      }
    },
    getEntityData(entity) {
      let action = this.eventList[entity.event];

      switch (entity.recordable_type) {
        case "App\\Models\\User":
          return {
            action: action,
            icon: "media/icons/duotone/Interface/User.svg",
            type: "Usuario",
            label:
              entity.properties.firstName + " " + entity.properties.lastName,
          };
        case "App\\Models\\DonorCheck":
          return {
            action: action,
            icon: "media/icons/duotone/Interface/User.svg",
            type: "Nuevo Control",
            label: "Control de Activa",
          };
        case "App\\Models\\Donor":
          return {
            action: action,

            icon: "media/icons/duotone/Communication/Address-card.svg",
            type: "Donante",
            label:
              entity.properties.firstName + " " + entity.properties.lastName,
          };
        case "App\\Models\\Transport":
          return {
            action: action,
            label: entity.properties.name,
            icon: "media/icons/duotone/Home/Building.svg",
            type: "Transportista",
          };
        case "Core\\Models\\Role":
          return {
            action: action,
            label: entity.properties.name,
            icon: "media/icons/duotone/General/Shield-protected.svg",
            type: "Perfil",
          };
        default:
          return {
            action: action,
            label: "ID: " + entity.properties.id,
            type: entity.recordable_type.split("\\").pop(),
          };
      }
    },
  },
});
